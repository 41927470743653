<template>
    <div class="topContent">
        <div class="top brand">
            <div class="right-btn">
                <slot name="button"></slot>
            </div>

            <div class="flex1"></div>
            <el-input
                size="mini"
                :placeholder="$t('sou-suo')"
                class="search"
                v-model="search"
                clearable
                @change="submitSearch"
                @clear="submitSearch"
            >
                <div slot="append" @click="submitSearch" class="iconfont">
                    &#xe603;
                </div>
            </el-input>
        </div>

        <el-divider></el-divider>

        <div class="bottom" v-if="!noBootom">
            <div class="typeSelect">
                <slot name="bottom-left"></slot>
            </div>

            <slot name="bottom-right"></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'topSelect',
    props: {
        searchKey: {
            type: String,
            default: ''
        },
        noBootom: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            search: ''
        };
    },
    watch: {
        searchKey() {
            this.$nextTick(() => {
                if (this.search !== this.searchKey) {
                    this.search = this.searchKey;
                }
            });
        },
        search() {
            this.$nextTick(() => {
                if (this.search !== this.searchKey) {
                    this.$emit('update:searchKey', this.search);
                }
            });
        }
    },
    methods: {
        submitSearch() {
            this.$nextTick(() => {
                this.$emit('submitSearch');
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.top {
    display: flex;
    height: 68px;
    align-items: center;
    .el-button {
        min-width: 120px;
        padding: 13px 15px;
        display: block;
    }
}
.right-btn {
    display: flex;
    align-items: center;
}
.el-divider {
    margin: 0;
}
.topContent {
    background-color: #fff;
    padding: 0 20px;
    .typeSelect {
        height: 51px;
        display: flex;
        align-items: center;
        .el-button--warning.is-plain {
            background-color: transparent;
            border-color: transparent;
            &:hover,
            &:checked,
            &:focus {
                color: $--color-warning;
            }
        }

        .el-button--info.is-plain {
            background-color: transparent;
            border-color: transparent;
            &:hover,
            &:checked {
                // background-color: #f3f4f5;
                color: $--color-warning;
            }
            &:focus {
                color: $--color-info;
            }
        }
    }
}

.search {
    height: 32px;
    display: flex;
    width: auto;
    margin-left: 30px;
}
.bottom {
    display: flex;
    align-items: center;
    overflow: hidden;
    .typeSelect {
        flex-grow: 1;
        overflow: hidden;

        .el-tabs {
            width: 100%;
        }
    }
}

.el-radio-group {
    flex-shrink: 0;
}
</style>
<style lang="scss">
.typeSelect {
    .el-tabs__header {
        margin: 0 10px 0 0;
        .el-tabs__active-bar {
            display: none;
        }

        .el-tabs__item {
            padding: 0 0;

            font-size: 12px;
            font-weight: normal;
            color: #878d99;

            &.is-active {
                color: #ffa526;
            }
        }
        .el-tabs__item + .el-tabs__item {
            margin-left: 0.5rem;
        }

        .el-tabs__nav-next,
        .el-tabs__nav-prev {
            line-height: 40px;
        }
    }

    .el-tabs__nav-wrap::after {
        content: none;
    }

    .el-tabs__content {
        display: none;
    }
}
</style>
