<template>
    <div class="newsInfo" @click="goDetail">
        <el-image :src="info.img" fit="cover"></el-image>

        <div class="content">
            <div class="title">
                <span>{{ info.title }}</span>
            </div>
        </div>
        <div class="flex1"></div>
        <el-button type="text" icon="el-icon-top" plain size="small" @click.stop.prevent="move(-1)" class="pre">{{$t('shang-yi')}}</el-button>
        <el-button type="text" icon="el-icon-bottom" plain size="small" @click.stop.prevent="move(1)" class="next"
            >{{$t('xia-yi')}}</el-button
        >
        <el-button
            type="text"
            icon="el-icon-edit"
            plain
            size="small"
            @click.stop.prevent="$router.push('/vendor/CaseEdit?id=' + info.id)"
            >{{$t('bian-ji')}}</el-button
        >
        <el-button type="text" icon="el-icon-delete" plain size="small" @click.stop.prevent="del">{{$t('shan-chu')}}</el-button>
    </div>
</template>
<script>
export default {
    name: 'NewsRow',
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    methods: {
        goDetail() {
            this.$router.push('/caseDetail?id=' + this.info.id);
        },
        move(direction) {
            this.$emit('move', {
                id: this.info.id,
                direction: direction
            });
        },
        del() {
            this.$emit('del', this.info.id);
        }
    }
};
</script>
<style lang="scss" scoped>
.newsInfo {
    display: flex;
    background-color: #fff;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    align-items: center;
    margin: 20px 20px 0;
    position: relative;

    border: 1px solid #e6e9f0;
    padding-right: 0.4rem;
    &:hover {
        // box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.08);
        .content {
            .title {
                // color: #ffa526;
            }
            .sub {
                // color: #ffa526;
            }
        }
        background-color: rgba(#bcc1cc, 0.1);
    }
    .el-image {
        width: 200px;
        height: 140px;
        flex-shrink: 0;
    }

    .content {
        box-sizing: border-box;
        padding: 0 0.2rem;
        position: relative;
        width: 60%;
        display: flex;
        flex-direction: column;
        height: 140px;
        .title {
            font-size: 13px;
            color: #292c33;
            line-height: 32px;
            transition: all ease-in-out 0.3s;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-weight: bold;

            .el-tag {
                margin-right: 6px;
            }
        }

        .time {
            font-size: 13px;
            color: #acb3bf;
            line-height: 24px;
        }

        .sub {
            font-size: 12px;
            color: #878d99;
            transition: all ease-in-out 0.3s;

            line-height: 22px;
            margin-top: 0.1rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }

    .el-button {
        height: 36px;
        background-color: transparent;
        min-width: 60px;
        border-width: 0;
        color: #bcc1cc;

        &:hover {
            color: #292c33;
        }
    }
}

.newsInfo {
    &:first-child {
        .pre {
            display: none;
        }
    }

    &:last-child {
        .next {
            display: none;
        }
    }
}
</style>
