<template>
    <div class="newsInfo" @click="goDetail">
        <el-image :src="info.img" fit="cover"></el-image>

        <div class="status" v-if="info.status == 'PENDING'">{{ $t('shen-he-zhong') }}</div>
        <div class="status error" v-else-if="info.status == 'DENY'">{{ $t('shen-he-shi-bai') }}</div>

        <div class="content">
            <div class="title">
                <el-tag type="warning" size="mini" effect="dark" v-if="first">{{ $t('re-men-tui-jian') }}</el-tag>

                <span>{{ info.title }}</span>
            </div>

            <div class="sub">
                {{ info.introduction }}
            </div>

            <div class="flex1"></div>

            <div class="time">{{ info.createdAt }}</div>
        </div>
        <div class="flex1"></div>

        <el-button
            type="warning"
            plain
            size="small"
            @click.stop.prevent="$router.push(`/vendor/NewsEdit?id=${info.id}${first ? '&first=1' : ''}`)"
            >{{ $t('bian-ji') }}</el-button
        >
    </div>
</template>
<script>
export default {
    name: 'NewsRow',
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        first: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        goDetail() {
            this.$router.push('/brandNewsDetail?id=' + this.info.id);
        }
    }
};
</script>
<style lang="scss" scoped>
.newsInfo {
    display: flex;
    background-color: #fff;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    align-items: center;
    padding: 20px;
    position: relative;
    &:hover {
        // box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.08);
        .content {
            .title {
                // color: #ffa526;
            }
            .sub {
                // color: #ffa526;
            }
        }
        background-color: rgba(#f5f7fa, 0.4);
    }
    .el-image {
        width: 200px;
        height: 140px;
        flex-shrink: 0;
    }

    .content {
        box-sizing: border-box;
        padding: 0 0.2rem;
        position: relative;
        width: 60%;
        display: flex;
        flex-direction: column;
        height: 140px;
        .title {
            font-size: 13px;
            color: #292c33;
            line-height: 32px;
            transition: all ease-in-out 0.3s;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-weight: bold;

            .el-tag {
                margin-right: 6px;
            }
        }

        .time {
            font-size: 13px;
            color: #acb3bf;
            line-height: 24px;
        }

        .sub {
            font-size: 12px;
            color: #878d99;
            transition: all ease-in-out 0.3s;

            line-height: 22px;
            margin-top: 0.1rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }

    .el-button {
        height: 36px;
        background-color: transparent;
        min-width: 80px;
        &:hover {
            background-color: #ffa526;
        }
    }
}

.newsInfo + .newsInfo {
    &::before {
        content: '';
        position: absolute;
        left: 20px;
        right: 20px;
        height: 1px;
        background-color: #f2f3f5;
        top: 0;
    }

    .el-tag {
        display: none;
    }
}

.status {
    position: absolute;
    background-color: rgba(#ffa526, 0.8);
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 0;
    width: 200px;
    text-align: center;
    bottom: 20px;
    left: 20px;

    &.error {
        background-color: rgba(#f56c6c, 0.8);
    }
}
</style>
